import React from "react";
import './footer.css'
import {Link} from "react-scroll";
import {useTranslation} from "react-i18next";
import file from "../img/Privacy Policy .pdf"


const Footer = () => {

    const {t} = useTranslation();

    return (
        <footer className="bg-dark">
            <div className="footer-top">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-3 col-sm-6">
                            <h2 className="mb-0 text-white name">Agile Talent Solutions</h2>
                            <link rel="stylesheet"
                                  href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"/>
                            <div className="social-icons">
                                <a href="https://www.linkedin.com/company/agilets/about/" target="_blank"><span><i className="bi bi-linkedin"></i></span></a>
                                <a href="mailto:contact@agilets.de" target="_blank"><span><i className="bi bi-envelope"></i></span></a>
                                <a href="https://www.instagram.com/agile_talent_solutions/" target="_blank"><span><i className="bi bi-instagram"></i></span></a>
                                <a href="https://api.whatsapp.com/send?phone=4915736627593&text=" target="_blank"><span><i className="bi bi-whatsapp"></i></span></a>
                            </div>
                        </div>
                        <div className=" t2 col-lg-3 col-sm-6 ml-auto">
                            <h5 className="mb-0 text-white">{t('about_us')}</h5>
                            <div className="line"></div>
                            <ul>
                                <li><Link to="/why"> {t('why')}</Link></li>
                                <li><Link to="/how-it-works"> {t('how_it_works')}</Link></li>
                                <li><Link to="/our-values"> {t('our_values')}</Link></li>
                                <li><Link to="/for-developers"> {t('for_developers')}</Link></li>
                                <li><a href={file} download>{t('privacy_policy')}</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 ml-auto">
                            <h5 className="mb-0 text-white">{t('contacts')}</h5>
                            <div className="line"></div>
                            <ul>
                                <li>contact@agilets.de</li>
                                <li>+4915736627593</li>
                                {/*<li>agilets.de</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row g-4 justify-content-between">
                        <div className="col-auto">
                            <p className="mb-0">© 2024 Agile Talent Solutions</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}
export default Footer;
