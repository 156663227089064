import React from 'react';
import cookies from 'js-cookie';
import './header.css';
import i18next from 'i18next';
import {IconButton} from "@mui/material";

export default function LanguageFlags() {
    const languages = [
        {code: 'en', country_code: 'gb', name: 'English'},
        {code: 'de', country_code: 'de', name: 'Deutsch'},
    ];

    const currentLanguageCode = cookies.get('i18next') || 'en';

    return (
        <div className='flags'>
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
            />
            {languages.map(({code, name, country_code}) => (
                <IconButton
                    key={code}
                    className='lang_icon MyCustomButton'
                    onClick={() => {
                        i18next.changeLanguage(code);
                        cookies.set('i18next', code); // Обновление куки с новым выбранным языком
                    }}
                >
                    <div className={`fi fl fi-${country_code}`} style={{
                        backgroundColor: '#e2dcd4',
                        opacity: currentLanguageCode === code ? 1 : 0.3
                    }}></div>
                </IconButton>
            ))}
        </div>
    );
}
