// Modal.js
import React, { useRef, useEffect } from 'react';
import './Modal.css';

const Modal = ({ show, handleClose, children }) => {
    const modalRef = useRef();

    const handleOverlayClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleOverlayClick);
        } else {
            document.removeEventListener('mousedown', handleOverlayClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content" ref={modalRef}>
                <button className="close-button" onClick={handleClose}>✕</button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
