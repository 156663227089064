import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-fs-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next).init({
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        de: {
            translations: require('./locales/de/translations.json')
        }
    },
    ns: ['translations'],
    fallbackLng: 'en',
    defaultNS: 'translations',
    backend: {
        loadPath: '/locales/en/translations.json',
    },
    detection: {
        order: ['cookie', 'localStorage'],
        caches: ['cookie'],
    },
});

i18n.languages = ['en', 'de'];

export default i18n;