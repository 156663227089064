import './App.css';
import Header from "./Components/Header";
import "bootstrap/dist/css/bootstrap.min.css"
import Footer from "./Components/Footer";
import React from "react";
import './i18n/config';


function App() {
    return (
        <div className="App">
            <Header/>
            <Footer/>
        </div>
    );
}

export default App;
