import React, { useRef, useState } from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

const Contacts = () => {
    const form = useRef();
    const { t } = useTranslation();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm('service_3q6ktoc', 'template_cvqervl', form.current, 'zlmBs9DB5WULcKFGJ')
            .then((result) => {
                console.log(result.text);
                setFormSubmitted(true);
            })
            .catch((error) => {
                console.log(error.text);
            });
        e.target.reset();
    };

    return (
        <div className="contact2" id="contact">
            {formSubmitted ? (
                <div className="container">
                    <i className="bi bi-check-circle" style={{ fontSize: '3rem', color: '#4caf50' }}></i>
                    <h4 className="title mt-3">{t("thank_you")}</h4>
                    <p>{t("we_will_contact_you")}</p>
                </div>
            ) : (
                <div className="container">
                    <div className="row contact-container">
                        <div className="col-lg-12 contacts">
                            <div className="row">
                                <div className="col-lg-8 box border-color contact-box">
                                    <div className="contact-box p-3">
                                        <h4 className="title">{t("contact_us")}</h4>
                                        <form ref={form} onSubmit={sendEmail}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mt-3">
                                                        <input className="form-control" type="text" name="name" placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mt-3">
                                                        <input className="form-control" name="phone" type="text" placeholder={t("LinkedIn")} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mt-3">
                                                        <input className="form-control" name="email" type="text" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mt-3">
                                                        <textarea className="form-control" name="message" rows="6" placeholder={t("message")} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <button type="submit" className="btn mt-3 mb-3 border-0 py-2 px-3">
                                                        <span>{t("send_message")} <i className="ti-arrow-right"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-4 detail bg-image border-color-right"
                                    style={{ backgroundImage: 'linear-gradient(to bottom, #b3aa9f, #ccc7c0)' }}
                                >
                                    <div className="detail-box p-4">
                                        <h5 className="text-black font-weight-light mb-3">Email</h5>
                                        <p className="text-black op-7">contact@agilets.de</p>
                                        <h5 className="text-black font-weight-light mb-3 mt-4">{t("call_us")}</h5>
                                        <p className="text-black op-7">+496879583029</p>
                                        <div className="col-lg-3 col-sm-6 icons">
                                            <div className="social-icons">
                                                <a href="https://www.linkedin.com/company/agilets/about/" target="_blank">
                                                    <i className="bi bi-linkedin"></i>
                                                </a>
                                                <a href="mailto:contact@agilets.de" target="_blank">
                                                    <i className="bi bi-envelope"></i>
                                                </a>
                                                <a href="https://www.instagram.com/agile_talent_solutions/" target="_blank">
                                                    <i className="bi bi-instagram"></i>
                                                </a>
                                                <a href="mailto:contact@agilets.de" target="_blank"><span><i className="bi bi-whatsapp"></i></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contacts;
