import React, {useState} from 'react';
import './ForDevelopers.css';
import {useTranslation} from "react-i18next";
import logoBack from "../img/logo.svg";
import logo from "../img/logo2.svg";
import ModalContact from "./ModalContact";
import Contacts from "./Contacts";

const ForDevelopers = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const handleIkigaiClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>

            <div className="for-developers-page">
                <div className="container">
                    <div className="for-developers">
                        <h1 className="title impact-gradient">{t('for_developers')}</h1>
                        <p>{t('for_developers_text_1')}
                            <span className="impact-gradient"> IMPACT </span>{t('for_developers_text_2')}</p>
                    </div>
                    <div className="impact-section">
                        <div className="impact-item">
                            {/**/}
                            <div className="impact-text">
                                <h3><span className="first-letter">I</span>ndividual Growth
                                </h3>
                                <p>{t('individual_growth_text')}</p>
                            </div>
                        </div>
                        <div className="impact-item">

                            <div className="impact-text">
                                <h3><span className="first-letter">M</span>obility</h3>
                                <p>{t('mobility_text')}
                                  </p>
                            </div>
                        </div>
                        <div className="impact-item">

                            <div className="impact-text">
                                <h3><span className="first-letter">P</span>ay Equity
                                </h3>
                                <p>{t('pay_equity_text')}</p>
                            </div>
                        </div>
                        <div className="impact-item">

                            <div className="impact-text">
                                <h3><span className="first-letter">A</span>ligned Projects</h3>
                                <p>{t('aligned_projects')}
                                    Through our individualized hiring process, we ensure you engage with projects that
                                    perfectly match your professional ambitions.</p>
                            </div>
                        </div>
                        <div className="impact-item">

                            <div className="impact-text">
                                <h3><span className="first-letter">C</span>onsistent Support
                                </h3>
                                <p>{t('consistent_support')}</p>
                            </div>
                        </div>
                        <div className="impact-item">

                            <div className="impact-text">
                                <h3><span className="first-letter">T</span>ransparent Communication</h3>
                                <p>{t('transparent_communication')}
                                </p>
                            </div>
                        </div>
                        <button type="submit" onClick={handleIkigaiClick} className="btn mt-3 mb-3 border-0 py-2 px-3">
                            <div className="make_it_happen">
                                <div className='logo'>
                                    {/*<ScrollAnimation animateIn='fadeIn animate__animated animate__fadeIn animate__delay-3s'>*/}
                                    <div className="flip-container">
                                        <div className="flipper">
                                            <div className="front">
                                                <img src={logo} className="d-inline-block align-top logo"
                                                     alt="logo"/>
                                            </div>
                                            <div className="back">
                                                <img src={logo} className="d-inline-block align-top logo" alt="logo"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/*</ScrollAnimation>*/}
                                </div>
                                <div>{t('apply_now')}<i className="ti-arrow-right"></i></div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <ModalContact show={showModal} handleClose={handleCloseModal}>
                <Contacts/>
            </ModalContact>
        </div>
    );
};

export default ForDevelopers;
