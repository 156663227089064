import React from "react";
import './HowWeWork.css';
import { useTranslation } from "react-i18next";
import circle from '../img/circle.png';
import star from '../img/star.png';
import { useInView } from 'react-intersection-observer';

const HowWeWork = () => {
    const { t } = useTranslation();
    const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
    return (
        <div className="how-we-work">
            <div className='title'>
                <h1>{t("enjoy_simple_and_clear_processes")}</h1>
            </div>
            <div className="timeline">
                <div ref={ref1} className={`hww_container left-container ${inView1 ? 'animate' : ''}`}>
                    <img alt="step1" src={circle} />
                    <div className="text-box">
                        <h2>{t("share_your_ideal_developer_requirements_with_us")}</h2>
                        <p>{t("tell_us_your_goals_and_conditions")}</p>
                        <span className="left-container-arrow"></span>
                    </div>
                </div>
                <div ref={ref2} className={`hww_container right-container ${inView2 ? 'animate' : ''}`}>
                    <img alt="step2" src={circle} />
                    <div className="text-box">
                        <h2>{t("meet_your_ideal_developers")}</h2>
                        <p>{t("interview_custom-matched_developers_after_our_detailed_presentation")}</p>
                        <span className="right-container-arrow"></span>
                    </div>
                </div>
                <div ref={ref3} className={`hww_container left-container ${inView3 ? 'animate' : ''}`}>
                    <img className="last-img" alt="step3" src={star} />
                    <div className="text-box">
                        <h2>{t("launch_remote_work")}</h2>
                        <p>{t("choose_who_to_hire")}</p>
                        <span className="left-container-arrow last-arrow"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowWeWork;
