import React, { useEffect, useState } from 'react';
import './main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { useTranslation } from 'react-i18next';
import logoBack from '../img/logo.svg';
import logo from '../img/logo2.svg';
import Calendly from './calendly';
import Modal from './Modal';

const Main = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const flipper = document.querySelector('.flipper');
        flipper.classList.add('flipped');
    }, []);

    const handleIkigaiClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className='section main'>
            <div className='name'>
                <h1 className="fade-in-down">
                    {t('We_are_delivering')} {t('custom_matched_developers')}<br />
                    {t('to_your_startup')}
                </h1>
                <p className="fade-in">{t('You_set_the_standard')}</p>
                <button type="submit" className="btn mt-3 mb-3 border-0 py-2 px-3">
                    <div className="make_it_happen" onClick={handleIkigaiClick}>
                        <div className="logo">
                            <div className="flip-container">
                                <div className="flipper flip-on-load">
                                    <div className="front">
                                        <img src={logoBack} className="d-inline-block align-top logo" alt="logo" />
                                    </div>
                                    <div className="back">
                                        <img src={logo} className="d-inline-block align-top logo" alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>{t('make_it_happen')}</div>
                    </div>
                </button>
            </div>
            <Modal show={showModal} handleClose={handleCloseModal}>
                <Calendly />
            </Modal>
        </div>
    );
}

export default Main;
