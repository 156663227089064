import React, { useState } from "react";
import './whyIkigai.css';
import ScrollAnimation from "react-animate-on-scroll";
import Calendly from "./calendly";
import Modal from "./Modal";
import './Ikigai.css';
import {useTranslation} from "react-i18next";

export default function WhyIkigai() {
    const [showModal, setShowModal] = useState(false);

    const handleIkigaiClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const { t } = useTranslation();
    return (
        <>
            <div className="why-container">
                <div className="why-page">
                    <div className="boxes">
                        <ScrollAnimation animateIn='fadeIn animate__animated animate__fadeIn animate__slower'>
                            <div className="box left-top">
                                <div className="headline"><h2>{t('custom-matched')}<br/>{t('developers')}</h2></div>
                                <p>
                                    {t('custom_matched_developers_text')}
                                </p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn animate__animated animate__fadeIn animate__slower'>
                            <div className="box right-top">
                                <div className="headline"><h2>{t('outstanding')}<br/>{t('performance')}</h2></div>
                                <p>
                                    {t('outstanding_performance_text')}
                                </p>
                            </div>
                        </ScrollAnimation>
                        <div className="ikigai-symbol">
                            <div className="iki">
                                <section className="c-m-dev"><p>{t('custom-matched')}<br/>{t('developers')}</p></section>
                                <section className="performance"><p>{t('outstanding')}<br/>{t('performance')}</p></section>
                                <section className="cost"><p>{t('cost')}<br/>{t('efficiency')}</p></section>
                                <section className="commitments"><p>{t('adaptable')}<br/>{t('commitments')}</p></section>
                                <section className="ikigai" onClick={handleIkigaiClick}>{t('get_your')}<br/>{t('perfect')}<br/>{t('developer')}</section>
                            </div>
                        </div>
                        <ScrollAnimation animateIn='fadeIn animate__animated animate__fadeIn animate__slower'>
                            <div className="box left-bottom">
                                <div className="headline"><h2>{t('adaptable')}<br/>{t('commitments')}</h2></div>
                                <p>
                                    {t('adaptable_commitments_text')}
                                </p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn animate__animated animate__fadeIn animate__slower'>
                            <div className="box right-bottom">
                                <div className="headline"><h2>{t('cost')}<br/>{t('efficiency')}</h2></div>
                                <p>
                                    {t('cost_efficiency_text')}

                                </p>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <Modal show={showModal} handleClose={handleCloseModal}>
                <Calendly />
            </Modal>
        </>
    );
}
