import React, { useState } from "react";
import './Great.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { useTranslation } from "react-i18next";

const Great = () => {
    const { t } = useTranslation();
    const [description, setDescription] = useState("");

    const handleBoxHover = (desc) => {
        setDescription(desc);
    }

    return (
        <div className='great'>
            <h1 className="title impact-gradient">{t('our_values')}</h1>
            <div id="card-area">
                <div className="wrapper">
                    <div className="box-area">
                        <div className="box" onMouseEnter={() => handleBoxHover(t('growth_through_learning_text'))}>
                            <div className="letter-container">
                                <div className="gradient-border">G</div>
                            </div>
                            <div className="value">Growth through Learning</div>
                        </div>
                        <div className="box" onMouseEnter={() => handleBoxHover(t('integrity_is_the_cornerstone'))}>
                            <div className="letter-container">
                                <div className="gradient-border">R</div>
                            </div>
                            <div className="value">Reliable Integrity</div>
                        </div>
                        <div className="box" onMouseEnter={() => handleBoxHover(t('clear_and_open_communication'))}>
                            <div className="letter-container">
                                <div className="gradient-border">E</div>
                            </div>
                            <div className="value">Effective Communication</div>
                        </div>
                        <div className="box"
                             onMouseEnter={() => handleBoxHover(t('our_team_upholds_a_high_level_of_professionalism'))}>
                            <div className="letter-container">
                                <div className="gradient-border">A</div>
                            </div>
                            <div className="value">Assured Competence</div>
                        </div>
                        <div className="box"
                             onMouseEnter={() => handleBoxHover(t('we_center_our_efforts_on_understanding'))}>
                            <div className="letter-container">
                                <div className="gradient-border">T</div>
                            </div>
                            <div className="value">Targeted Client Focus</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="description">{description}</div>
        </div>
    );
}
export default Great;
